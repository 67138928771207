<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">neighborhood: points of interest</div>
        <div class="module-preview--contents">
            <div class="font-600 text-lg">{{ getValue('title') }}</div>
            <p class="mt-4 text-sm" :inner-html.prop="getValue('description')"></p>
        </div>
    </div> 
    <!-- <div>
        <div class="text-left text-xs bg-black text-white opacity-50 p-1">neighborhood: points of interest</div>
        <div class="bg-gray-200 flex items-center justify-center mb-8 px-4 py-8">
            <div
                v-for="(poi,index) in pois"
                :key="index"
                class="mx-2 border border-gray-500"
            >
                <div class="px-2 py-1 block">
                    <icon :name="poi.point_of_interest_category_icon" class="w-4 h-4 inline "/>
                    {{ poi.point_of_interest_category_name }}
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
// import Icon from "@/components/ui/Icon";

export default {
    mixins: [ Widget ],
    // components: { Icon },
    computed: {
        ...mapGetters({
            pois: 'points_of_interest/getPointsOfInterest'
        }),
    },
}
</script>
<style scoped>
    .module-preview--contents{
        display:block;
        grid-gap: unset;
    }
    .module-preview--contents p{
        max-width: 100ch;
    }    
</style>